import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { RootState } from '@store/index';
import { RootActions } from '@store/root.actions';
import { PageMetasSelectors } from '@store/page-metas';

import { IconComponent } from '../icon/icon.component';

import { TSocialMediaPlatformConfig } from '@interfaces/social-media';
import { SocialLinks } from '@interfaces/config/cms-components/social-links-config';
import { DEFAULT_SOCIAL_LINKS_CONFIG, IS_SERVER, SPS } from '@core/config';

import { capitalize } from 'lodash-es';

@Component({
  selector: 'sps-social-links',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslateModule],
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialLinksComponent implements OnInit {
  @Input() expanded = false;

  @Input() set config(config: Partial<SocialLinks.ComponentConfig>) {
    this.$config.update(c => ({ ...c, ...config }));
  }

  public $links: WritableSignal<SocialLinks.LinkItemConfig[]> = signal([]);
  public $config: WritableSignal<SocialLinks.ComponentConfig> = signal({
    ...DEFAULT_SOCIAL_LINKS_CONFIG,
  });

  private readonly i18RootKey = 'SHARED.COMPONENTS';
  private readonly $pageTitle: Signal<string>;

  constructor(
    @Inject(IS_SERVER) @Optional() private isServer: boolean,
    private store: Store<RootState>,
    private translateService: TranslateService
  ) {
    this.$pageTitle = this.store.selectSignal(PageMetasSelectors.selectTitle);
  }

  ngOnInit(): void {
    if (this.isServer) {
      return;
    }

    this.buildLinks();
  }

  public i18n(key: string, extras: string = null): string {
    const collection: string[] = [];

    if (extras) {
      collection.push(extras);
    } else {
      collection.push(this.i18RootKey, 'SOCIAL_LINKS');
    }

    collection.push(key);

    return collection.join('.');
  }

  private buildLinks(): void {
    const links = Object.entries(SPS.SOCIAL_MEDIA_PLATFORMS).reduce((coll, [platform, platformConfig]) => {
      if (!this.$config()[platform]) {
        return coll;
      }

      let href = platformConfig.url;
      let title = { i18n: this.i18n('LINK_TITLE'), options: { platform: capitalize(platform) } };

      if (this.$config().sharing && platformConfig.sharing) {
        href = this.buildPlatformHref(platformConfig);
        title.i18n = this.i18n('SHARE_LINK_TITLE');
      }

      return [...coll, { href, icon: { name: platform.toLowerCase(), custom: true }, title }];
    }, [] as SocialLinks.LinkItemConfig[]);

    if (this.$config().email) {
      links.push(this.buildEmailLinkConfig());
    }

    this.$links.set(links);
  }

  public showNewsletterDialog() {
    this.store.dispatch(RootActions.showNewsletterDialog());
  }

  private buildPlatformHref(platformConfig: TSocialMediaPlatformConfig): string {
    const url = new URL(platformConfig.sharing.endpoint);

    Object.entries(platformConfig.sharing.params).forEach(([key, value]) => {
      let paramValue: string;

      switch (value) {
        case '[URL]':
          paramValue = this.getUrl();
          break;
        case '[PAGE_TITLE]':
          paramValue = this.$pageTitle().replaceAll(/ /g, '____');
          break;
        default:
          paramValue = value;
      }

      url.searchParams.set(key, paramValue);
    });

    return url.toString().replaceAll(/____/g, ' ');
  }

  private buildEmailLinkConfig(): SocialLinks.LinkItemConfig {
    const body = this.translateService.instant(this.i18n('EMAIL_BODY'), { url: this.getUrl() });
    const href = { subject: this.$pageTitle(), body };

    return {
      title: { i18n: this.i18n('LINK_TITLE_EMAIL') },
      icon: { name: 'email', custom: false },
      href:
        'mailto:?' +
        Object.entries(href)
          .map(([k, v]) => `${k}=${v}`)
          .join('&'),
    };
  }

  private getUrl(): string {
    return location.href;
  }
}
