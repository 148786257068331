@if ($config().message) {
  <strong class="message">{{ i18n('MESSAGE') | translate }}</strong>
}

<ul [class.expanded]="expanded">
  @for (link of $links(); track link.href) {
    <li>
      <a [href]="link.href" target="_blank" [title]="link.title.i18n | translate: link.title.options">
        <sps-icon [icon]="link.icon.name" [custom]="link.icon.custom"></sps-icon>

        @if (expanded) {
          <span>{{ link.title.options.platform }}</span>
        }
      </a>
    </li>
  }
  @if ($config().newsletter) {
    <li>
      <button (click)="showNewsletterDialog()" [title]="i18n('NEWSLETTER_LINK_TEXT', 'GENERAL') | translate">
        <sps-icon icon="feed"/>
        @if (expanded) {
          <span>{{ i18n("NEWSLETTER_LINK_TEXT", "GENERAL") | translate }}</span>
        }
      </button>
    </li>
  }
</ul>
